//? Library Imports ------------------------------------------------------------>
import { Row, Col, Carousel, Typography, Button } from "antd";

//? Assets --------------------------------------------------------------------->
import "./ProductsCarousel.scss";
import {
  SlickNextArrow,
  SlickPrevArrow,
} from "../Buttons/CarouselButtons/CarouselButtons";

//? Modules -------------------------------------------------------------------->
import ProductCard from "./ProductCard/ProductCard";
import { useEffect, useState } from "react";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";
import Link from "next/link";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function ProductsCarousel({
  categoryName,
  data,
  title,
  userAgent,
  type,
  buttonText,
  buttonLink,
  buttonColor,
}) {
  //? Hooks ---------------------------------------------------------------------->
  const [loading, setLoading] = useState(true);
  const { isMobile, isDesktop } = userAgent;
  //let noOfSlides = products?.products?.length > 5 ? true : false;

  // console.log(type);
  const settings = {
    // speed: 500,
    swipe: true,
    dots: false,
    autoplay: false,
    infinite: false,
    arrows: !isMobile,
    slidesToShow: !isDesktop ? 2.2 : 5,
    slidesToScroll: !isDesktop ? 2 : 1,
    // autoplayspeed: 1500,
    prevArrow: <SlickPrevArrow />,
    nextArrow: <SlickNextArrow />,
  };

  useEffect(() => {
    if (data?.length) {
      setLoading(false);
    }
  }, [data]);

  return (
    <Row
      align="middle"
      justify="center"
      className={
        !isDesktop
          ? "ProductsCarousel products-carousel-container padding0"
          : "ProductsCarousel padding0 products-carousel-container"
      }
    >
      {title ? (
        <Col
          span={24}
          className="sectionHeadingContainer side-bullet text-center mt-10 mb-10"
        >
          <Text
            className={
              isDesktop
                ? "sectionHeadingBold"
                : "sectionHeadingMobileBold textCenter"
            }
          >
            {title}
          </Text>
        </Col>
      ) : null}

      <Col span={24}>
        <div
          className={
            !isDesktop
              ? "carousel_container paddingLeft5 "
              : "carousel_container "
          }
        >
          {loading ? (
            <div style={{ display: "flex" }}>
              {isMobile ? (
                <>
                  {[1, 2].map((index) => (
                    <div key={index} style={{ flex: 1, marginRight: 16 }}>
                      <CustomSkeletonBox width="100%" height={250} />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {[1, 2, 3, 4, 5].map((index) => (
                    <div key={index} style={{ flex: 1, marginRight: 16 }}>
                      <CustomSkeletonBox width="100%" height={371} />
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : (
            <Carousel {...settings}>
              {data?.length &&
                data?.map((pro, key) => {
                  return (
                    <div key={key} className="padding5">
                      <ProductCard
                        type={type}
                        product={pro}
                        categoryName={categoryName}
                        userAgent={userAgent}
                      />
                    </div>
                  );
                })}
            </Carousel>
          )}
        </div>
        {!isDesktop && buttonText && buttonLink && buttonColor ? (
          <div className="products-carousel-mobile-button">
            <Link
              href={`${buttonLink}${
                buttonLink.includes("?") ? "&" : "?"
              }popup=true`}
              style={{ color: `${buttonColor} !important` }}
            >
              {buttonText}
            </Link>
          </div>
        ) : null}
      </Col>
    </Row>
  );
}
